@import url("../../variable.less");

.container {
	display: flex;
	flex-direction: column;

	.nav {
		height: 48px;
		display: flex;
		justify-content: center;
		padding: 0 14px;

		:global {
			.adm-nav-bar-back-arrow {
				display: flex;
				align-items: center;
				& > img {
					width: 24px;
				}
			}
			.adm-nav-bar-title {
				font-size: 16px;
				font-family: Noto Sans, Noto Sans;
				font-weight: 500;
			}
		}
	}

	.primary {
		:global {
			.adm-nav-bar-title {
				color: white;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow-y: auto;
		padding: 14px;
	}
}
