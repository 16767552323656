@import url("./page/shared/variable.less");

:global {
	body {
		.adm-input,
		.adm-text-area {
			background-color: #fff;
			padding: 12px;
			border-radius: 8px;
			font-size: 14px;
			font-weight: 400;
			color: #1e1e1e;
			border: 1px solid transparent;
			box-sizing: border-box;

			&:hover,
			&:focus,
			&:active {
				border-color: @primary;
			}

			.adm-input-element {
				font-size: 14px;
			}

			.adm-text-area-element {
				font-size: 12px;
			}
		}

		.adm-form-item-has-error {
			.adm-text-area,
			.adm-input {
				border-color: @loss;
			}
		}

		.adm-tabs-tab-disabled {
			opacity: 1;

			& > :first-child {
				opacity: 0.5;
			}
		}

		.adm-form {
			.adm-list-item,
			.adm-list-body {
				background-color: transparent;

				.adm-list-item-content {
					width: 100%;

					.adm-list-item-content-main {
						width: 100%;
						padding: 0;

						.adm-form-item-label {
							color: #1e1e1e;
							font-family: "PingFang SC";
							font-size: 14px;
							font-weight: 500;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;

							.tiny-font {
								color: #999;
								font-size: 12px;
								font-weight: 300;
							}
						}

						.adm-form-item-child {
							min-height: 48px;
							margin-top: 8px;
							padding: 0;
							height: auto;
						}

						.adm-list-item-description {
							margin-top: 4px;
							min-height: 20px;

							.adm-form-item-feedback-error {
								margin: 0;
							}
						}
					}
				}
			}
		}

		.adm-popup-body-position-bottom {
			background-color: #fff;
			border-radius: 26px 26px 0 0;
		}

		.border {
			border: 1px solid transparent;
			box-sizing: border-box;

			&:hover,
			&:focus,
			&:active {
				border-color: @primary;
			}
		}

		.btn {
			border-radius: 6px;
			font-family: "PingFang SC";
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		.primary-btn {
			.btn();
			background-color: @primary;
			border-color: @primary;
			color: #fff;
		}

		.disable-btn {
			.btn();
			background-color: #ccc;
			border-color: #ccc;
			color: #fff;
			opacity: 1;

			&:active,
			&:hover,
			&:focus {
				opacity: 1 !important;
			}
		}

		.ghost-btn {
			background-color: transparent;
			border-color: transparent;
			color: @primary;
		}

		.outline-btn {
			.btn();
			background-color: transparent;
			border-color: @primary;
			color: @primary;
		}

		.custom-modal {
			.adm-center-popup-wrap {
				width: calc(100vw - 40px);
				max-width: calc(100vw - 40px);
				max-height: 80%;
				overflow-y: scroll;
			}
		}

		.adm-center-popup-body {
			border-radius: 19px;
		}

		.update-modal {
			.adm-center-popup-wrap {
				width: calc(100vw - 40px);
				max-width: calc(100vw - 40px);

				.adm-center-popup-body {
					box-sizing: border-box;
					padding: 31px 16px 16px;
					background-image: url("./page/shared/assets/background/checkUpdate.png");
					background-repeat: no-repeat;
					background-size: cover;
					font-size: 16px;
					letter-spacing: 0.16px;

					& > * {
						padding: 0;
					}

					.adm-modal-title {
						margin-bottom: 11px;
						color: #1e1e1e;
						font-weight: 500;
						font-size: 16px;
					}

					.adm-modal-content {
						text-align: justify;
						color: #5d6066;
						font-size: 12px;
						letter-spacing: 0.12px;
						word-break: break-word;
					}

					.adm-space {
						margin-top: 32px;

						.adm-button {
							height: 40px;
							background-color: @primary;
							border-radius: 8px;
							color: #fff;
							font-size: 16px;
							padding: 0;
						}
					}
				}
			}
		}

		.avatar {
			border-radius: 50%;
			background: #ebf7ff;
			justify-content: center;
			display: flex;
			align-items: center;
			align-self: center;

			& > span {
				text-align: center;
				font-weight: bold;
				color: #005ff9;
			}
		}
	}
}
