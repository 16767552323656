@headerColor: #f3f3f3;
@earn: green;
@loss: red;

@background: #f5f7fa;
@primary: #005ff9;
@disable: #abaeb2;
@TRC: #fff6f6;
@BEP: #fffaf0;
@ERC: #f0f3ff;

@grey: #939599;
@danger: #df3636;
@normalFontColor: #1e1e1e;
@lightFontColor: #666;
@greyBorder: 1px solid #e4e6eb;
@warnColor: #fff6e6;

.base-form {
	:global {
		.adm-list-item {
			padding: 0;
		}

		.adm-list-item-content {
			padding: 0;

			.adm-form-item-label {
				font-weight: 700;
			}

			.adm-form-item-child {
				background-color: @background;
				align-items: center;
				height: 40px;
				border-radius: 8px;
				padding: 8px;
				box-sizing: border-box;
			}
		}
	}
}

.form-without-border {
	.base-form();

	:global {
		.adm-list-body {
			border: none;
		}

		.adm-list-item-content {
			border: none;
		}
	}
}

.horizontal-flex {
	display: flex;
	flex-direction: row;
}

.vertical-flex {
	display: flex;
	flex-direction: column;
}

.flex-between {
	display: flex;
	justify-content: space-between;
}

.vw100 {
	width: 100vw;
}
