.loading-container {
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 3;
	border-radius: 8px;
	height: 100%;
	box-sizing: border-box;

	img {
		animation: spin 1s linear infinite;
	}
	@keyframes spin {
		100% {
			transform: rotate(360deg);
		}
	}
}
