body {
	overflow: hidden;
}

.app {
	font-size: 16px;
	flex-direction: column;
	min-width: 360px;
	height: 100vh;
	box-sizing: border-box;
	overflow: hidden;
	background: linear-gradient(180deg, #d7e7ff 0%, #f5f7fa 160px, #f5f7fa 100%);

	font-variant-numeric: lining-nums proportional-nums;
	font-feature-settings: "dlig" on;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	vertical-align: middle;
}

.adm-picker-view-mask-bottom {
	-webkit-mask: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8) 50%, #000);
}
.adm-picker-view-mask-top {
	-webkit-mask: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8) 50%, #000);
}

.flex-between {
	display: flex;
	justify-content: space-between;
}

.align-center {
	align-items: center;
}

.base-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.w100 {
	width: 100%;
}

.pd20 {
	padding: 20px;
}

.pdt0 {
	padding-top: 0;
}

.mglr20 {
	margin: 0 20px;
}

.base-msg {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding-top: 12px;
}

.normal-msg {
	color: #485169;
}

.warn-msg {
	color: #86909c;
}

.loss {
	color: #f83464 !important;
}

.profit {
	color: #2eb574 !important;
}

.svg .fill {
	fill: currentColor !important;
}

.link {
	color: #0066ff;
}

.time {
	font-size: 10px;
	font-weight: 400;
	color: #939599;
}
