@import url("../shared/variable.less");

.layout {
	.vw100();

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.content {
		max-width: 100%;
		flex: 1;
		overflow-x: hidden;
		overflow-y: auto;
		padding-top: 8px;
	}

	.navigation {
		width: 100%;
		height: 40px;
		display: flex;
		justify-content: space-between;
		height: 48px;
		background-color: #fff;
		border-top: 1px solid #e4e6eb;

		.activity {
			color: @primary;
		}

		.normal {
			color: @disable;
		}

		& > * {
			display: flex;
			flex: 1;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 12px;

			svg {
				width: 24px;
			}
		}
	}
}
